body {
    background-color: '#FFFFFF';
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.flex {
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.bold-title-font {
    font-size: 20px;
    font-weight: bold;
}

.bolder-title-font {
    font-size: 20px;
    font-weight: 600;
}

.expanded-ellipsis {
    display: flex;
    gap: 10px;
}

.verification-box-holder {
    min-width: 24px;
    max-width: 24px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.full-width {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.profile-image-holder {
    background-color: transparent;
    cursor: pointer;
    border: 0;
}

.popper-button {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 0;
    height: 24px;
}

.popper-dropdown-box {
    border: 0;
    padding: 0;
    background-color: white;
}

.profile-header-rectangle-desktop {
    height: 348px;
    width: 100%;
    background-color: #D2E4E5;
    background: linear-gradient(90deg, rgba(55, 209, 218, 0.1) 0%, rgba(83,194,191,0.55) 100%);
    box-shadow: 0 1px 2px 0 #E3E8EE;
    text-align: -webkit-center;
}

.profile-header-rectangle-mobile {
    height: 610px;
    width: 100%;
    background-color: #D2E4E5;
    background: linear-gradient(90deg, rgba(55, 209, 218, 0.1) 0%, rgba(83,194,191,0.55) 100%);
}

.profile-card-paper {
    box-sizing: border-box !important;
    border: 1px solid #DADCE0 !important;
    border-radius: 12px !important;
    box-shadow: 0 1px 2px 0 #E3E8EE !important;
}

.required:after {
    content:" *";
    color: red;
    font-size: 14px;
}

.onboarding-bg-rectangle {
    height: 100%;
    width: 100%;
    background: #FFFFFF
}

.general-onboarding-bg {
    height: 100%;
    width: 100%;
    background-color: #D2E4E5;
    background: linear-gradient(90deg, rgba(210,228,229,0.1) 0%, rgba(83,194,191,0.55) 100%);
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.general-onboarding-desktop {
    float: left;
    position: relative;
    margin-left: 25px;
    top: 8rem;
    width: 55%;
}

.general-onboarding-mobile {
    margin-bottom: -25%;
    width: 75%
}

.share-data-finish-loader {
    height: 20px !important;
    width: 20px !important;
    margin-right: 10px;
}

.data-manager-img-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.data-manager-general-onboarding-container-wrapper {
    padding-right: 0 !important;
}